// Chakra imports
import {
  Box,
  Button,
  Flex,
  Icon,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
// Custom components
import React from "react";
import { MdSecurity } from "react-icons/md";

export default function WeeklyRevenue(props) {
  const { ...rest } = props;

  // Move useColorModeValue to the top level of the component
  const textColor = useColorModeValue("gray.700", "white");

  // Mock data
  const statistics = [
    { label: "Shift", value: "Morning" },
    { label: "Total Nodes", value: 52 },
    { label: "Total Online Nodes", value: 33 },
    { label: "Total Workers", value: 357 },
    { label: "Total Workers Connected", value: 250 },
    { label: "Safety Incidents", value: 3 },
    { label: "Resolved Issues", value: 2 },
    { label: "Pending Issues", value: 1 },
    { label: "Uptime (hours)", value: 10 },
    { label: "Safety Drills Conducted", value: 5 },
  ];

  return (
    <Card {...rest}>
      <Flex direction="column" p="20px">
        <Text color={textColor} fontSize="lg" fontWeight="bold" mb="6px">
          Mining Operation Metrics
        </Text>
        <Box>
          {statistics.map((stat, index) => (
            <Flex key={index} justify="space-between" mb="4px" fontSize={{ sm: '14px' }}
                          minW={{ sm: '150px', md: '200px', lg: 'auto' }}>
              <Text color={textColor} fontSize={{ sm: '14px' }}
                          minW={{ sm: '150px', md: '200px', lg: 'auto' }}>{stat.label}</Text>
              <Text color={textColor} fontSize={{ sm: '14px' }}
                          minW={{ sm: '150px', md: '200px', lg: 'auto' }} fontWeight="semibold">{stat.value}</Text>
            </Flex>
          ))}
        </Box>
      </Flex>
    </Card>
  );
}
